import * as React from 'react';

import {cn} from '../../lib/utils';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  append?: string | JSX.Element;
  prepend?: string | JSX.Element;
};
const styledInput =
  'tw-flex tw-h-10 tw-w-full tw-border-b tw-border-t-0 tw-border-border tw-bg-background tw-px-0 tw-py-2 tw-justify-start tw-text-base tw-leading-[1.375rem] tw-ring-offset-background file:tw-border-0 file:tw-bg-transparent file:tw-text-base file:tw-font-normal placeholder:tw-text-muted focus:tw-border-t-0 focus:tw-border-l-0, focus:tw-border-r-0 focus:tw-border-borderFocus focus-visible:tw-outline-none disabled:tw-cursor-not-allowed disabled:tw-opacity-50 read-only:tw-cursor-not-allowed read-only:tw-bg-transparent';

const Input = React.forwardRef<HTMLInputElement, InputProps>(({className, type, ...props}, ref) => {
  if (props.append || props.append !== undefined) {
    return (
      <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
        <input type={type} className={cn(styledInput, className)} ref={ref} {...props} />
        {typeof props.append === 'string' && props.append.length && (
          <span className="tw-font-bold tw-leading-[1.375rem tw-pl-3">{props.append}</span>
        )}
        {React.isValidElement(props.append) && <span className="tw-absolute tw-right-0">{props.append}</span>}
      </div>
    );
  }
  if (props.prepend || props.prepend !== undefined) {
    return (
      <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-relative">
        {typeof props.prepend === 'string' && props.prepend.length && (
          <span className="tw-font-bold tw-leading-[1.375rem tw-pr-3 -tw-m-4">{props.prepend}</span>
        )}
        {React.isValidElement(props.prepend) && props.prepend}
        <input type={type} className={cn(styledInput, className)} ref={ref} {...props} />
      </div>
    );
  }
  return <input type={type} className={cn(styledInput, className)} ref={ref} {...props} />;
});
Input.displayName = 'Input';

export {Input};
