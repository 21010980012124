import {useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {MoveMode} from '../../api/ContractsAPI';
import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {Info} from '../../components/Info';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {RadioGroup} from '../../components/inputs/RadioGroup';
import {RadioInput} from '../../components/inputs/RadioInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {Contract, ContractDetails} from '../../models/Contract';
import {IOrganization} from '../../models/Organization';
import {translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';

interface MoveContractModalProps extends IPromiseModalProps<boolean> {
  contract: ContractDetails;
}

function checkInput(mode: MoveMode, organization: IOrganization | undefined): string | undefined {
  switch (mode) {
    default:
      return T('contracts.move.error.selectMode');
    case MoveMode.ContractOnly:
      if (organization === undefined) {
        return T('contracts.move.error.selectOrganization');
      } else if (!organization.linkedToOdoo) {
        return T('contracts.move.error.mustHaveOdooPartner');
      } else return undefined;
    case MoveMode.ContractAndPartner:
      if (organization === undefined) {
        return T('contracts.move.error.selectOrganization');
      } else if (organization.linkedToOdoo) {
        return T('contracts.move.error.mustNotHaveOdooPartner');
      } else return undefined;
    case MoveMode.NewOrganization:
      return undefined;
  }
}

export function MoveContractModal(props: MoveContractModalProps) {
  const {contract} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const [mode, setMode] = useState<MoveMode>(MoveMode.Choose);
  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState<IOrganization>();

  const [name, setName] = useState<string>('');
  const [vat, setVAT] = useState<string>('');
  const [error, setError] = useState<string>();

  const handleSave = () => {
    return api.contracts
      .move(contract.id, {
        mode,
        organizationId: organization?.id,
        companyName: name,
        companyVat: vat
      })
      .then(() => {
        NotificationManager.success(T('contracts.move.success'));
        resolve(true);
        return undefined;
      })
      .catch(e => {
        setError(translateError(e, T('contracts.move.error')));
        return undefined;
      });
  };

  const inputError = checkInput(mode, organization);

  return (
    <SingleActionModal
      isOpen={isOpen}
      title={T('contracts.move.title')}
      onToggle={() => resolve(false)}
      action={handleSave}
      error={error || inputError}
      disabled={inputError !== undefined}
    >
      {contract.contract === Contract.eMSPPersonal && (
        <p style={{color: 'gray', marginTop: '1rem'}}>{T('contracts.move.emspBecomesBusiness')}</p>
      )}
      <RadioGroup label={T('contracts.move.mode.label')}>
        <RadioInput
          name="mode"
          value="contract_only"
          checked={mode === MoveMode.ContractOnly}
          label={T('contracts.move.mode.contractOnly')}
          onChange={checked => checked && setMode(MoveMode.ContractOnly)}
        />
        <RadioInput
          name="mode"
          value="contract_and_partner"
          checked={mode === MoveMode.ContractAndPartner}
          label={T('contracts.move.mode.contractAndPartner')}
          onChange={checked => checked && setMode(MoveMode.ContractAndPartner)}
          disabled={!contract.customerInOdoo}
        />
        <RadioInput
          name="mode"
          value="new_organization"
          checked={mode === MoveMode.NewOrganization}
          label={T('contracts.move.mode.newOrganization')}
          onChange={checked => checked && setMode(MoveMode.NewOrganization)}
          disabled={!contract.customerInOdoo}
        />
      </RadioGroup>
      {mode === MoveMode.ContractOnly && <Info>{T('contracts.move.mode.contractOnly.info')}</Info>}
      {mode === MoveMode.ContractAndPartner && <Info>{T('contracts.move.mode.contractAndPartner.info')}</Info>}
      {mode === MoveMode.NewOrganization && <Info>{T('contracts.move.mode.newOrganization.info')}</Info>}
      {(mode === MoveMode.ContractOnly || mode === MoveMode.ContractAndPartner) && (
        <OrganizationInputGroup
          name="organization"
          label={T('contracts.move.organization.label')}
          organizations={inputOrganizations.organizations}
          value={organization}
          onChange={setOrganization}
          onUpdateQuery={updateOrganizationInputQuery}
        />
      )}
      {(mode === MoveMode.NewOrganization ||
        (mode === MoveMode.ContractAndPartner && contract.contract === Contract.eMSPPersonal)) && (
        <>
          <TextInputGroup name="name" label={T('contracts.move.organization.name')} value={name} onChange={setName} />
          <TextInputGroup name="vat" label={T('contracts.move.organization.vat')} value={vat} onChange={setVAT} />
        </>
      )}
      {error && <Info>{error}</Info>}
    </SingleActionModal>
  );
}
